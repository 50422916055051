import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';

const routes: Routes = [
  { path: '', redirectTo: 'home', pathMatch: 'full' },
  {
    path: 'home',
    loadChildren: () => import('./pages/home/home.module').then(m => m.HomePageModule)
  },
  { path: 'modal', loadChildren: () => import('./pages/modal/modal.module').then(m => m.ModalPageModule) },
  {
    path: 'arranjos',
    loadChildren: () => import('./pages/arranjos/arranjos.module').then(m => m.ArranjosPageModule)
  },
  { path: 'store',  loadChildren: () => import('./pages/store/store.module').then(m => m.StorePageModule) },
  { path: 'admdenise', loadChildren: './pages/admdenise/admdenise.module#AdmdenisePageModule' },
  { path: 'livros', loadChildren: () => import('./pages/livros/livros.module').then(m => m.LivrosPageModule)},
  { path: 'quadros',  loadChildren: () => import('./pages/quadros/quadros.module').then(m => m.QuadrosPageModule) },
  { path: 'exibicao-arranjos', loadChildren: () => import('./pages/exibicao-arranjos/exibicao-arranjos.module').then(m => m.ExibicaoArranjosPageModule) },
  {
    path: 'paisagismo',
    loadChildren: () => import('./pages/paisagismo/paisagismo.module').then( m => m.PaisagismoPageModule)
  }
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
