
import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { InterfaceArranjosValue } from "../services/interfaces";


@Injectable()
export class Services {
         baseUrl = "https://rochajoiasdeautor.com.br/api/";
         valor = 0;
         totalValor: string = this.convertToMoney(this.valor);
         instrucaoArranjos = "Selecione uma base";

         itensSelected = new Array();
         itensInStore = new Array();

         optionsArranjosValue: Array<InterfaceArranjosValue>;

         categorioArranjosSelected: number;
         token: string;

         constructor(public http: HttpClient) {}

         initializeApp() {}

         getArranjoItemFromServer = (type: string) => {
           const json = JSON.stringify({ action: type });
           return this.http.post(this.baseUrl + "itens", json);
         };

         addToServer = (type: string, json) => {
           console.log("token " + this.token);
           const data = {
             action: type,
             token: this.token,
             url: json["url"],
             category: json["category"],
             description: json["description"]
           };

           const jsonToServer = JSON.stringify(data);
           return this.http.post(this.baseUrl + "itens", jsonToServer);
         };

         removeToServer = (type: string, json) => { 
                     
           const data = {
             action: type,
             token: this.token,
             id: json["id"],            
           };
           const jsonToServer = JSON.stringify(data);
           console.log("data " + jsonToServer)
           return this.http.post(this.baseUrl + "itens", jsonToServer);
         };

         login = (type: string, json: any) => {
           const data = {
             action: type,
             user: json.user,
             pass: json.password
           };

           const jsonToServer = JSON.stringify(data);
           return this.http.post(this.baseUrl + "login", jsonToServer);
         };

         getArranBasesFromServer = (type: string, _category: string) => {
           const json = JSON.stringify({ action: type, category: _category });
           return this.http.post(this.baseUrl + "itens", json);
         };

         getArranjoSubItemFromServer = (type: string) => {
           const json = JSON.stringify({ action: type });
           return this.http.post(this.baseUrl + "itens", json);
         };

         getArranjoOptionsFromServer = (type: string) => {
           const json = JSON.stringify({ action: type });
           this.http
             .post(this.baseUrl + "store", json)
             .subscribe((data: any) => {
               this.optionsArranjosValue = data.optionsValueArranjos;
               this.categorioArranjosSelected = this.optionsArranjosValue[0].value;
             });
         };

         convertToMoney(valor) {
           let inteiro = null,
             decimal = null,
             c = null,
             j = null;
           const aux = new Array();
           valor = "" + valor;
           c = valor.indexOf(".", 0);
           // encontrou o ponto na string
           if (c > 0) {
             // separa as partes em inteiro e decimal
             inteiro = valor.substring(0, c);
             decimal = valor.substring(c + 1, valor.length);
           } else {
             inteiro = valor;
           }

           // pega a parte inteiro de 3 em 3 partes
           for (j = inteiro.length, c = 0; j > 0; j -= 3, c++) {
             aux[c] = inteiro.substring(j - 3, j);
           }

           // percorre a string acrescentando os pontos
           inteiro = "";
           for (c = aux.length - 1; c >= 0; c--) {
             inteiro += aux[c] + ".";
           }
           // retirando o ultimo ponto e finalizando a parte inteiro

           inteiro = inteiro.substring(0, inteiro.length - 1);

           // tslint:disable-next-line: radix
           decimal = parseInt(decimal);
           if (isNaN(decimal)) {
             decimal = "00";
           } else {
             decimal = "" + decimal;
             if (decimal.length === 1) {
               decimal = decimal + "0";
             }
           }

           valor = "R$ " + inteiro + "," + decimal;

           return valor;
         }
       }
