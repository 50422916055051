import { Component } from '@angular/core';
import { Location } from '@angular/common';
import {
  Platform,
  NavController,

  ModalController
} from '@ionic/angular';
//import { SplashScreen } from '@ionic-native/splash-screen/ngx';
//import { StatusBar } from '@ionic-native/status-bar/ngx';
import { Router } from '@angular/router';
//import { ModalPage } from './pages/modal/modal.page';
import { Services } from './services/service';
@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss']
})
export class AppComponent {
  isCompraModalOpened = false;
  isInHome = true;
  showGlobalHeader = true;
  openMobile = false

  constructor(
    private _services: Services,
    private platform: Platform,
    private navCtrl: NavController,
   // private splashScreen: SplashScreen,
   // private statusBar: StatusBar,
    private router: Router,
    private location: Location,
    //public events: Events,
    public modalController: ModalController
  ) {
    this.initializeApp();

    /*
    events.subscribe('nav', isInHome => {
      this.isInHome = isInHome;
    });

    events.subscribe('showGlobalHeader', header => {
      this.showGlobalHeader = header;
    }); */
  }

  initializeApp() {
    this.platform.ready().then(() => {
     // this.statusBar.styleDefault();
     // this.splashScreen.hide();
      if (window.location.pathname != '/admdenise') {
        this.navCtrl.navigateBack('/home');
      }
      this.router.navigate(['/home']);
    });
  }

  navTo = nav => {
    // this.router.navigate([nav]);
    if (this.openMobile) { this.openMobile = false }

    if (nav == 'home') {
      this.navCtrl.navigateBack('/home');
    } else {
      this.router.navigate([nav]);
    }
    // this.location.back();
  }

  openModal = () => {
    /*
    if (!this.isCompraModalOpened) {
      this.modalController
        .create({
          component: ModalPage,
          cssClass: 'modal-fullscreen',
          componentProps: {
            something: 'ola'
          }
        })
        .then(modal => {
          return modal.present();
        });
    } */
  }

  closeModal = () => {
    try {
      this.navCtrl.pop();
    } catch (error) {
      console.log(error);
    }
  }

  checkRouterActiveted = (router) => {

    if (this.router.url == router) return true
  }
}
